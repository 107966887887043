import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isNil } from 'lodash';
import { Subscription } from 'rxjs';

import { GlobalEventsService } from 'src/app/_core/global-events.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';
import { StoreService } from 'src/app/_core/store.service';
import { ItemCardService } from 'src/app/item-card/item-card.service';
import { ItemCardState, ItemCardStateDTO } from 'src/app/item-card/models/item-card-state.model';
import { ItemCardUpdateMessage } from 'src/app/item-card/models/item-card-update-message.model';
import { ItemLabel } from 'src/app/item-card/models/item-label.model';
import { ItemSimple } from 'src/app/item-card/models/item-simple.model';

@Component({
  selector: 'agr-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss']
})
export class ItemCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('imageContainer') readonly imageContainer: ElementRef;
  @Output() readonly closeItemCard = new EventEmitter<Event>();

  isPage = false;
  isLocked = false;
  isRecalculating = false;
  isEmpty = true;

  itemId: number;
  orderId: number;
  item = new ItemSimple();
  itemLabels: ItemLabel[];

  stateKey = 'item-card.state';
  state: ItemCardState = new ItemCardState();

  windowWidth = 0;

  hasImage = false;
  imageUrl = '';
  imageMaxHeight: number;

  hasNewWindowSupport = 'BroadcastChannel' in self;

  refreshItemEvent = new EventEmitter<void>();
  refreshDetailsColumnEvent = new EventEmitter<string>();
  subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private applicationRef: ApplicationRef,
    private globalEventsService: GlobalEventsService,
    private itemCardService: ItemCardService,
    private settingsService: SettingsService,
    private storeService: StoreService
  ) {}

  ngOnInit(): void {
    this.setupPopout();
    this.loadState();
    this.checkHasImage();
    // Subscriptions
    this.subscriptions.push(
      this.globalEventsService.updateItemCardEvent$.subscribe((data: ItemCardUpdateMessage) => {
        this.updateItemCard(data);
      })
    );
    this.subscriptions.push(
      this.globalEventsService.refreshItemCardEvent$.subscribe((data: ItemCardUpdateMessage) => {
        this.refreshItem(data.itemId);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.reflowChart();
  } // reflowChart for image max height and windowWidth

  toggleTitle(): void {
    this.state.toggleItemTitle();
    this.saveState();
  }

  toggleLockItem(): void {
    this.isLocked = !this.isLocked;
  }

  refreshItem(itemId?: number): void {
    const undefinedItem = itemId === undefined;
    const sameItem = itemId === this.itemId;
    if (!undefinedItem && !sameItem) {
      return;
    } // Ignore refresh of different item
    this.getItem();
    if (!this.isEmpty) {
      this.refreshItemEvent.emit(); // Notifies child components to reload data
    }
  }

  refreshDetails(column?: string): void {
    this.globalEventsService.refreshDetailsColumn(column);
  }

  toggleSidebar(): void {
    this.state.sidebar.visible = !this.state.sidebar.visible;
    this.saveState();
    this.reflowChart();
  }

  toggleImageSidebar(): void {
    this.state.imageSidebar.visible = !this.state.imageSidebar.visible;
    this.saveState();
    this.reflowChart();
  }

  toggleCard(tab: 'showChart' | 'showGrid' | 'showDetails'): void {
    this.state.toggle(tab);
    this.saveState();
    this.reflowChart();
  }

  toggleNextCard(): void {
    this.state.nextCard();
    this.saveState();
    this.reflowChart();
  }

  toggleChartPeriod(): void {
    this.state.nextChart();
    this.saveState();
  }

  stepIncrementHistory(): void {
    this.state.stepIncrementHistory();
    this.saveState();
  }

  stepIncrementForecast(): void {
    this.state.stepIncrementForecast();
    this.saveState();
  }

  updateForecastLength(value: number): void {
    this.state.updateForecastLength(value);
    this.saveState();
  }

  updateHistoryLength(value: number): void {
    this.state.updateHistoryLength(value);
    this.saveState();
  }

  recalculatePurchasePlan(): void {
    if (!this.itemId) {
      return;
    }
    this.isRecalculating = true;
    this.itemCardService.recalculatePurchasePlan(this.itemId, this.state.chart).subscribe(
      () => {
        this.isRecalculating = false;
        this.globalEventsService.refreshItemCard(this.itemId);
      },
      () => {
        this.isRecalculating = false;
      }
    );
  }

  openItemWindow(): void {
    this.closeItemCard.emit();
    let newWindow = `top=${window.screenTop + window.innerHeight / 2},left=${window.screenLeft}`;
    newWindow = `${newWindow},width=${window.innerWidth},height=${window.innerHeight / 2}`;
    window.open(`${document.location.origin}/selected-item`, '_blank', newWindow);
    setTimeout(() => {
      this.globalEventsService.updateItemCard(this.itemId, this.orderId);
    }, 3000); // Delay before broadcasting focused itemId
  }

  close(): void {
    this.isPage ? window.close() : this.closeItemCard.emit();
  }

  saveState(): void {
    this.storeService.set(this.stateKey, this.state.toDto());
  }

  private getItem(): void {
    this.item = new ItemSimple();
    if (isNil(this.itemId)) {
      this.isEmpty = true;
      return;
    }
    this.isEmpty = false;
    this.imageUrl = '';
    this.applicationRef.tick(); // Refresh DOM when page is in background, this starts loading overlay in separate window
    this.itemCardService.getSimpleItem(this.itemId).subscribe((data) => {
      this.item = data;
      this.setImageUrl();
      this.applicationRef.tick(); // Refresh DOM when page is in background
    });
    this.itemCardService.getItemLabelList(this.itemId).subscribe((data) => {
      this.itemLabels = data;
    });
  }

  private loadState(): void {
    const loadedState = this.storeService.load(this.stateKey, this.state.toDto()) as ItemCardStateDTO;
    this.state = new ItemCardState(loadedState);
  }

  private setupPopout(): void {
    this.isPage = this.activatedRoute.snapshot.routeConfig.path === 'selected-item';
    if (this.isPage) {
      this.globalEventsService.hideNavBar();
    }
  }

  private updateItemCard(updateMessage: ItemCardUpdateMessage): void {
    if (updateMessage.refresh) {
      return this.refreshItem(updateMessage.itemId);
    }
    if (updateMessage.column) {
      this.refreshDetailsColumnEvent.emit(updateMessage.column);
      return;
    }
    if (this.itemId === updateMessage.itemId && this.orderId === updateMessage.orderId) {
      return;
    }
    if (this.isLocked) {
      return;
    }
    if (updateMessage.isBroadcast && !this.isPage) {
      return;
    } // Do not update if outside update request and Item Card is docked/footer
    this.itemId = updateMessage.itemId;
    this.orderId = updateMessage.orderId;
    this.getItem();
  }

  private checkHasImage(): void {
    this.hasImage = !!this.settingsService.imageLookupProperty();
  }

  private setImageUrl(): void {
    const lookupProperty = this.settingsService.imageLookupProperty();
    this.hasImage = !!lookupProperty;
    const imageId = this.item.getPropertyValue(lookupProperty);
    if (!lookupProperty) {
      return;
    }
    const prefix = this.settingsService.imagePrefix() || '';
    const postfix = this.settingsService.imagePostfix() || '';
    this.imageUrl = `${prefix}${imageId}${postfix}`;
    this.imageUrl = !this.imageUrl ? 'assets/images/product-image-placeholder.png' : this.imageUrl;
  }

  private reflowChart(): void {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1); // Reflow/Scale Highcharts + Dispatches onResize as well
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize', ['$event']) onResize(): void {
    this.windowWidth = window.innerWidth;
    if (!this.imageContainer) {
      return;
    }
    this.imageMaxHeight = this.imageContainer.nativeElement.offsetHeight - 20;
  }
}
